<template>
  <vx-card>

    <!-- table -->
    <custom-vs-table
      ref="table"
      pagination
      stripe
      sst
      search
      hoverFlat
      :data="lists.data"
      :max-items="lists.meta.pagination.per_page"
      :total="lists.meta.pagination.total"
      @search="handleSearch"
      @change-page="handleChangePage">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between my-2">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">

          <!-- ADD NEW -->
          <vs-button
            color="success"
            type="border"
            icon="icon-plus"
            icon-pack="feather"
            :to="{
              name: 'user.new'
            }">
            Add New
          </vs-button>
        </div>

      </div>

      <template slot="thead">
        <vs-th class="thead">No</vs-th>
        <vs-th class="thead" sort-key="name">Name</vs-th>
        <vs-th class="thead" sort-key="email">Email</vs-th>
        <vs-th class="thead">Role</vs-th>
        <vs-th class="thead">Status</vs-th>
        <vs-th class="thead">Date</vs-th>
        <vs-th class="thead">Action</vs-th>
      </template>

      <template>
        <vs-tr v-for="(user, index) in lists.data" :key="index">
          <vs-td>
            {{ rowNo(index) }}
          </vs-td>
          <vs-td :data="user.name">
            {{ user.name }}
          </vs-td>
          <vs-td :data="user.email">
            {{ user.email }}
          </vs-td>
          <vs-td>
            <cell-renderer-roles :roles="user.roles"/>
          </vs-td>
          <vs-td>
            <cell-renderer-status :status="user.active"/>
          </vs-td>
          <vs-td>
            <span>
              {{ $helpers.dateFormat(user.created_at) }}
            </span>
          </vs-td>
          <vs-td>
            <vs-button
              class="float-left"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-edit-2"
              :to="{
                name: 'user.edit',
                params: {
                  id: user.id
                }
              }"/>
            <vs-button
              class="float-left"
              color="danger"
              type="filled"
              icon-pack="feather"
              icon="icon-trash-2"
              @click.prevent="remove(user.id)"/>
          </vs-td>
        </vs-tr>
      </template>
    </custom-vs-table>

  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import listMixin from '@/mixins/listMixin'

import CustomVsTable from '@/components/table/CustomVsTable'
import CellRendererStatus from '@/components/table/cell-renderer/Status'
import CellRendererRoles from '@/components/table/cell-renderer/Roles'

export default {
  mixins: [listMixin],
  components: {
    CustomVsTable,
    CellRendererStatus,
    CellRendererRoles,
  },

  computed: {
    ...mapGetters('user', ['lists']),
  },
  methods: {
    ...mapActions('user', ['fetch', 'delete', 'reset']),

    async _fetch () {
      this.locked = true

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
  },

  async mounted () {
    this._fetch()
  },
}
</script>

<style lang="scss">

</style>
