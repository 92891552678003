<template>
  <div>
    <vs-chip
      class="ag-grid-cell-chip"
      color="warning"
      v-for="(role, index) in roles"
      :key="index">
      <span>{{ role }}</span>
    </vs-chip>
  </div>
</template>

<script>
export default {
  name: 'CellRendererRoles',
  props: {
    roles: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
