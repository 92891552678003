var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[_c('custom-vs-table',{ref:"table",attrs:{"pagination":"","stripe":"","sst":"","search":"","hoverFlat":"","data":_vm.lists.data,"max-items":_vm.lists.meta.pagination.per_page,"total":_vm.lists.meta.pagination.total},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage}},[_c('div',{staticClass:"flex flex-wrap-reverse items-center flex-grow justify-between my-2",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex flex-wrap-reverse items-center data-list-btn-container"},[_c('vs-button',{attrs:{"color":"success","type":"border","icon":"icon-plus","icon-pack":"feather","to":{
            name: 'user.new'
          }}},[_vm._v(" Add New ")])],1)]),_c('template',{slot:"thead"},[_c('vs-th',{staticClass:"thead"},[_vm._v("No")]),_c('vs-th',{staticClass:"thead",attrs:{"sort-key":"name"}},[_vm._v("Name")]),_c('vs-th',{staticClass:"thead",attrs:{"sort-key":"email"}},[_vm._v("Email")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Role")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Status")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Date")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Action")])],1),_vm._l((_vm.lists.data),function(user,index){return _c('vs-tr',{key:index},[_c('vs-td',[_vm._v(" "+_vm._s(_vm.rowNo(index))+" ")]),_c('vs-td',{attrs:{"data":user.name}},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('vs-td',{attrs:{"data":user.email}},[_vm._v(" "+_vm._s(user.email)+" ")]),_c('vs-td',[_c('cell-renderer-roles',{attrs:{"roles":user.roles}})],1),_c('vs-td',[_c('cell-renderer-status',{attrs:{"status":user.active}})],1),_c('vs-td',[_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.dateFormat(user.created_at))+" ")])]),_c('vs-td',[_c('vs-button',{staticClass:"float-left",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-edit-2","to":{
              name: 'user.edit',
              params: {
                id: user.id
              }
            }}}),_c('vs-button',{staticClass:"float-left",attrs:{"color":"danger","type":"filled","icon-pack":"feather","icon":"icon-trash-2"},on:{"click":function($event){$event.preventDefault();return _vm.remove(user.id)}}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }